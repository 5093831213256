import Actions from "modules/actions/client-accounts-actions";

export default {
  name: "GenerateModal",
  props: {
    client: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions: new Actions(),
      menu: "",
      date: "",
      orders: [],
      facilities: [],
      selectedFacility: {
        defaultAddress: {
          id: null,
          line1: "",
          line2: "",
          city: "",
          stateProvince: "",
          postalCode: "",
        },
      },
      generationData: {
        invoiceDate: "",
        payment: "",
        paidAmount: "",
        amountDue: "",
        balanceForward: "",
        generationPayments: {
          current: {
            label: "Current",
            value: "",
          },
          payment2: {
            label: "31-60",
            value: "",
          },
          payment3: {
            label: "61-90",
            value: "",
          },
          payment4: {
            label: "91-120",
            value: "",
          },
          payment5: {
            label: "121-150",
            value: "",
          },
          payment6: {
            label: "151-180",
            value: "",
          },
          payment7: {
            label: "Over 181",
            value: "",
          },
        },
      },
      ordersHeaders: [
        {
          value: "dateOfService",
          text: "Date of Service",
          sortable: false,
        },
        {
          value: "accessionNumber",
          text: "Accession#",
          sortable: false,
        },
        {
          value: "patientName",
          text: "Patient Name",
          sortable: false,
        },
        {
          value: "dob",
          text: "DOB",
          sortable: false,
        },
        {
          value: "cptCode",
          text: "CPT",
          sortable: false,
        },
        {
          value: "cptDescription",
          text: "CPT Description",
          sortable: false,
        },
        {
          value: "amount",
          text: "Amount",
          sortable: false,
        },
        {
          value: "misCode",
          text: "Misc Code",
          sortable: false,
        },
        {
          value: "adjustment",
          text: "Adjustment",
          sortable: false,
        },
        {
          value: "balance",
          text: "Balance",
          sortable: false,
        },
      ],
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null)
            return (val.id && val.id !== null) || "This field is required.";

          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateNumber: (val) => {
          const numCount = val.split(".");

          if (numCount.length > -1)
            if (numCount[1].length > 2) return "Only two decimals are allowed.";

          return (
            1 / val !== -Infinity || "Negative float number is not allowed."
          );
        },
      },
    };
  },
  computed: {
    getOrdersData() {
      return this.data.map((item) => {
        const {
          dateSampleTaken,
          sampleId,
          patientName,
          patientDOB,
          cptCode,
          cptDescription,
          amount,
          misCode,
          adjustment,
          balance,
        } = item;

        return {
          dateOfService: dateSampleTaken,
          accessionNumber: sampleId,
          patientName: patientName,
          dob: patientDOB,
          cptCode,
          cptDescription,
          amount,
          misCode,
          adjustment,
          balance,
        };
      });
    },
  },
  methods: {
    closeDateModal(date) {
      this.generationData.invoiceDate = this.formatDate(date);
      this.$refs.menu1.save();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    async getFacilities() {
      try {
        if (!this.facilities.length) {
          this.facilities = [
            { text: "Fetching facilities...", disabled: true },
          ];

          const result = await this.actions.getSampleStorageLaboratories({
            order: {
              by: "orderByLabel",
              order: "ASC",
            },
            limitData: [
              "label",
              this.buildSubQuery("defaultAddress", [
                "line1",
                "line2",
                "stateProvince",
                "city",
                "country",
                "postalCode",
              ]),
            ],
          });

          if (result) {
            this.facilities = result.map((item) => {
              return {
                text: item.label,
                value: item,
              };
            });
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async generate() {
      await this.$refs.paidAmount.$refs.form.validateAsync();
      await this.$refs.balanceForward.$refs.form.validateAsync();
      await this.$refs.payment.$refs.form.validateAsync();
      await this.$refs.amountDue.$refs.form.validateAsync();

      await Promise.all(
        this.$refs.generationPayments.map(async (item) => {
          await item.$refs.form.validateAsync();
        })
      );

      for (let i of Object.keys(this.$refs)) {
        if (
          /^orderAmount/.test(i) ||
          /^orderMiscode/.test(i) ||
          /^orderAdjustment/.test(i) ||
          /^orderBalance/.test(i)
        )
          this.$refs[i].$refs.form.validateAsync();
      }

      await this.$refs.form.validateAsync();
    },
    formatAddress(address) {
      const { line1, city, stateProvince, postalCode } = address;

      return {
        firstLine: line1 || "",
        secondLine: `${city ? city + "," : ""} ${
          stateProvince ? " " + stateProvince : ""
        } ${postalCode ? " " + postalCode : ""}`,
      };
    },
    cancelGeneration() {
      this.$emit("cancelGeneration");
    },
    clearSelectedFacility() {
      this.selectedFacility = {
        defaultAddress: {
          id: null,
          line1: "",
          line2: "",
          city: "",
          stateProvince: "",
          postalCode: "",
        },
      };
    },
  },
};
