import Actions from "modules/actions/client-accounts-actions";

import ClientInvoice from "modules/main/ClientsPhysicians/Clients/sections/ClientInvoice";
import EditDetails from "modules/main/ClientsPhysicians/Clients/sections/EditDetails";
import Orders from "modules/main/ClientsPhysicians/Clients/sections/Orders";

export default {
  name: "EditClient",
  components: {
    EditDetails,
    Orders,
    ClientInvoice,
  },
  data() {
    return {
      actions: new Actions(),
      tab: "",
      client: {},
      limitData: [
        "payorCode",
        "subType",
        "payorName",
        this.buildSubQuery("npi", ["npiNumber"]),
        "tinNumber",
        "taxonomyCode",
        "effectivityDate",
        "terminationDate",
        this.buildSubQuery("addresses", [
          "line1",
          "line2",
          "city",
          "postalCode",
          "stateProvince",
          "country",
          this.buildSubQuery("addressType", ["label"]),
        ]),
        this.buildSubQuery("phones", [
          "phone",
          "label",
          this.buildSubQuery("phoneType", ["label"]),
        ]),
        this.buildSubQuery("contactInformations", [
          this.buildSubQuery("contactType", ["label"]),
          "firstName",
          "middleName",
          "lastName",
          "nameTitle",
          this.buildSubQuery("mainPhone", ["phone", "label"]),
          this.buildSubQuery("mainFax", ["phone", "label"]),
          this.buildSubQuery("contactEmail", [
            "email",
            this.buildSubQuery("emailType", ["label"]),
          ]),
        ]),
        this.buildSubQuery("contractualInformations", [
          "billingStatementType",
          "statementBillingTerm",
          "orderCode",
          this.buildSubQuery("testId", ["label"]),
          "contractRate",
          "feeSchedule",
        ]),
        this.buildSubQuery("physicians", [
          "physicianId",
          "physicianType",
          "firstName",
          "middleName",
          "lastName",
          this.buildSubQuery("npi", ["npiNumber"]),
          this.buildSubQuery("stateMedicalLicense", ["licenseNumber"]),
          "licenseState",
          "licenseStatus",
          this.buildSubQuery("defaultPhone", ["phone", "label"]),
          this.buildSubQuery("defaultEmail", [
            "email",
            this.buildSubQuery("emailType", ["label"]),
          ]),
          "effectivityDate",
          "terminationDate",
        ]),
        "salesRepId",
        "salesRepName",
        "saleRepTerritoryRegion",
      ],
    };
  },

  mounted() {
    this.tab = window.location.hash.replace("#", "");
  },
};
