import Actions from "modules/actions/client-accounts-actions";
import ClientInvoice from "modules/actions/client-invoice-actions";

const clientInvoicingProp = [
  "clientId",
  "clientName",
  "totalBilled",
  "totalPaid",
  "currentBalance",
  "totalAdjustment",
];
const currency = [
  "totalBilled",
  "totalPaid",
  "currentBalance",
  "totalAdjustment",
];
export default {
  name: "ClientInvoice",
  props: {},
  data() {
    return {
      actions: new Actions(),
      ciActions: new ClientInvoice(),
      clientInvoicing: null,
      clientInvoicingCopy: null,
      selectedAge: {
        minAgeDays: 0,
        maxAgeDays: 30,
        totalBalance: 0,
      },
      totalCount: 0,
      noInvoice: false,
      loading: { table: true },
      agingModalVisibility: false,
      auditTrails: {
        id: null,
        isVisible: false,
      },
      scheduledProcessor: [
        {
          name: "invoiceDate",
          align: "left",
          text: "Date of Invoice",
          field: "invoiceDate",
        },
        {
          name: "invoiceNumber",
          align: "left",
          text: "Invoice Number",
          field: "invoiceNumber",
        },
        {
          name: "currentBalance",
          align: "left",
          text: "Current Balance",
          field: "currentBalance",
        },
      ],
      columns: [
        {
          name: "invoiceDate",
          align: "left",
          text: "Date of Invoice",
          field: "invoiceDate",
        },
        {
          name: "invoiceNumber",
          align: "left",
          text: "Invoice Number",
          field: "invoiceNumber",
          nowrap: true,
        },
        {
          name: "totalBilled",
          align: "left",
          text: "Total Billed",
          field: "totalBilled",
        },
        {
          name: "totalPaid",
          align: "left",
          text: "Total Paid",
          field: "totalPaid",
        },
        {
          name: "totalAdjustment",
          align: "left",
          text: "Total Adjustment",
          field: "totalAdjustment",
        },
        {
          name: "currentBalance",
          align: "left",
          text: "Current Balance",
          field: "currentBalance",
        },
        {
          name: "adjCode",
          align: "left",
          text: "Adj Code",
          field: "revenueCode",
        },
        {
          name: "action",
          text: "Action",
          align: "left",
          field: "action",
        },
      ],
      invoicesLimitData: [
        this.buildSubQuery("invoices", [
          "invoiceNumber",
          "invoiceDate",
          "totalBilled",
          "totalPaid",
          "totalAdjustment",
          "currentBalance",
          "totalCount",
          this.buildSubQuery("revisions", [
            "invoiceNumber",
            "revisionNumber",
            "invoiceDate",
            "created",
            "lastModified",
            "isDeleted",
            "timeDiscarded",
            "timeProcessed",
            "timeGenerated",
            "timeSent",
          ]),
          this.buildSubQuery("revenueCode", ["code"]),
        ]),
      ],
      invoicingSummary: [],
      agingSummary: [],
    };
  },
  computed: {
    invoices() {
      if (!this.clientInvoicing?.invoices) return [];
      const { invoices } = this.clientInvoicing;
      const data = invoices.map(invoice => ({
        ...invoice,
        hideView: invoice.revisions?.length > 1 && invoice.totalBilled != 0,
        hideEdit: invoice.totalBilled == 0,
        invoiceDate: this.$options.filters.formatDateTime(invoice.invoiceDate, {
          format: "MM/DD/YYYY",
        }),
        totalBilled: this.getFormattedAmount(invoice.totalBilled),
        totalPaid: this.getFormattedAmount(invoice.totalPaid),
        totalAdjustment: this.getFormattedAmount(invoice.totalAdjustment),
        currentBalance: this.getFormattedAmount(invoice.currentBalance),
        revenueCode: invoice?.revenueCode?.code || "-",
        hasAuditTrail: invoice.totalBilled === 0,
        additionalMoreCommands: invoice.totalBilled === 0 ? []: [
          {
            label: "View/Edit Transaction Details",
            action: () => this.$router.push(
              `/system-maintenance/clients-physicians/clients/invoice/${invoice.id}/transactions`
            ),
          },
        ],
      }));
      return data;
    },
  },
  methods: {
    populateAgingSummary(data) {
      let toPopulate = [];
      if (!data) return [];
      const { agingBuckets } = data.aging;

      for (let i = 0; i < agingBuckets.length; i++) {
        const isClickable = agingBuckets[i].totalBalance != 0;
        let data = {
          header: agingBuckets[i].label,
          data: agingBuckets[i].totalBalance,
          prefix: "$",
          currency: true,
          clickable: isClickable,
        };

        const { minAgeDays, maxAgeDays } = agingBuckets[i];

        data.returnValue = {
          header: agingBuckets[i].label,
          minAgeDays: minAgeDays,
          maxAgeDays: maxAgeDays,
          totalBalance: this.formatCurrency(
            agingBuckets[i].totalBalance,
            2,
            "$"
          ),
        };

        if (!agingBuckets[i].totalBalance != 0)
          data["icon"] = {
            show: true,
            message: "No available invoice for this aging summary.",
          };
        toPopulate.push(data);
      }

      this.agingSummary = [
        {
          cols: toPopulate,
        },
      ];
    },
    populateInvoicingSummary(data) {
      let toPopulate = [];
      if (!data) return toPopulate;

      for (let i = 0; i < clientInvoicingProp.length; i++) {
        let isCurrency = currency.includes(clientInvoicingProp[i]);
        toPopulate.push({
          header: clientInvoicingProp[i]
            .replace(/([A-Z])/g, " $1")
            .replace(/Id/, "ID"),
          data: data[clientInvoicingProp[i]],
          currency: isCurrency,
          prefix: isCurrency ? "$" : "",
        });
      }
      let currentBalance = toPopulate.splice(4, 1);
      this.invoicingSummary = [{ cols: [...toPopulate, ...currentBalance] }];
    },
    openAgingModal(value) {
      this.agingModalVisibility = true;
      this.selectedAge.minAgeDays = value.minAgeDays;
      this.selectedAge.maxAgeDays = value.maxAgeDays;
      this.selectedAge.totalBalance = value.totalBalance;
      this.selectedAge.header = value.header;
    },

    async getAgingInvoices(pagination) {
      try {
        const filter = {
          minAgeDays: this.selectedAge.minAgeDays,
          maxAgeDays: this.selectedAge.maxAgeDays,
          logicalOperator: "AND",
        };

        const { aging } = await this.actions.getClientInvoicing({
          variables: {
            property: {
              payorAccountId: {
                type: "UUID!",
                value: this.$route.params.id,
              },
              ["aging.agingBucketInvoices.limit"]: {
                type: "Int",
                value: pagination?.limit || 10,
              },
              ["aging.agingBucketInvoices.offset"]: {
                type: "Int",
                value: pagination?.offset || 0,
              },
              ["aging.agingBucketInvoices.filter"]: {
                type: "VbsClientInvoiceAgingFilter",
                value: filter,
              },
            },
          },
          limitData: [
            this.buildSubQuery(
              "aging",
              [
                this.buildSubQuery(`agingBucketInvoices`, [
                  "invoiceNumber",
                  "invoiceDate",
                  "totalBilled",
                  "currentBalance",
                  "totalPaid",
                  "totalCount",
                ]),
              ],
              false
            ),
          ],
        });

        aging.agingBucketInvoices.map((item) => {
          item.currentBalance = this.formatCurrency(
            item.currentBalance,
            2,
            "$"
          );
          item.invoiceDate = item.invoiceDate
            ? this.$options.filters.getTimeDate(item.invoiceDate, "MM/DD/YYYY")
                .zone
            : "";
          return item;
        });

        this.$eventBus.$emit(
          "getVbsClientInvoicingSetDataList",
          aging.agingBucketInvoices
        );
      } catch (e) {
        this.showNotifyMessage({
          message: "An error occur while fetching aging summary data.",
          type: "danger",
        });
      }
    },
    click(data) {
      console.log(data);
    },
    async viewClientInvoice(id) {
      const url = await this.ciActions.viewClientInvoice({
        property: {
          clientInvoiceId: {
            type: "UUID!",
            value: id,
          },
        },
      });
      window.open(url);
    },
    addInvoice() {
      return;
    },
    async getClientInvoicing(params) {
      return await this.actions.getClientInvoicing(params);
    },
    async clientInvoicingPagination(pagination) {
      try {
        this.loading.table = true;
        const response = (
          await this.getClientInvoicing({
            variables: {
              property: {
                payorAccountId: {
                  type: "UUID!",
                  value: this.$route.params.id,
                },
                ["invoices.limit"]: {
                  type: "Int",
                  value: pagination?.limit || 10,
                },
                ["invoices.offset"]: {
                  type: "Int",
                  value: pagination?.offset || 0,
                },
              },
            },
            limitData: this.invoicesLimitData,
          })
        ).invoices;
        this.totalCount = response[0]?.totalCount;
        this.clientInvoicing.invoices = response;
      } catch {
        this.showNotifyMessage({
          message: "An error occur while fetching invoice(s) data.",
          type: "danger",
        });
      } finally {
        this.loading.table = false;
      }
    },
    toggleAuditTrail(isVisible, id = null) {
      this.auditTrails = {
        id,
        isVisible,
      }
    },
    getFormattedAmount(amount) {
      if (amount === 0) return "$ 0.00";
      return this.formatCurrency(amount, 2, "$");
    }
  },
  mounted() {
    this.$nextTick(async () => {
      try {
        const data = await this.actions.getClientInvoicing({
          variables: {
            property: {
              payorAccountId: {
                type: "UUID!",
                value: this.$route.params.id,
              },
            },
          },
          limitData: [
            "clientId",
            "clientName",
            "totalBilled",
            "totalPaid",
            "currentBalance",
            "totalAdjustment",
            this.buildSubQuery(
              "aging",
              [
                this.buildSubQuery(
                  "agingBuckets(numberOfBuckets: 7, days: 30)",
                  ["minAgeDays", "maxAgeDays", "label", "totalBalance"],
                  false
                ),
              ],
              false
            ),
            this.invoicesLimitData,
          ],
        });

        if (data?.invoices.length == 0) {
          this.noInvoice = true;
          this.loading.table = false;
          return;
        }
        this.populateAgingSummary(data);
        this.populateInvoicingSummary(data);
        this.totalCount = data.invoices[0]?.totalCount;
        this.clientInvoicing = JSON.parse(JSON.stringify(data));
      } catch (e) {
        this.showNotifyMessage({
          message: "An error occur while fetching invoice(s) data.",
          type: "danger",
        });
      } finally {
        this.loading.table = false;
      }
    });
  },
};
