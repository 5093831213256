import Actions from "modules/actions/client-accounts-actions";

import GenerateModal from "modules/main/ClientsPhysicians/Clients/modals/GenerateModal";

import Order from "store/models/Order";

export default {
  name: "ClientOrders",
  components: {
    GenerateModal,
  },
  data() {
    return {
      actions: new Actions(),
      filter: "",
      generateModalVisibility: false,
      isLoading: true,
      isDataLoading: false,
      totalCount: 0,
      orders: [],
      forGeneration: [],
      client: {},
      selectAction: [
        {
          label: "Generate",
          action: (result) => {
            this.showGenerateModal(result);
          },
        },
      ],
      columns: [
        {
          name: "internalId",
          align: "left",
          text: "Order ID",
          field: "internalId",
          sortable: true,
        },
        {
          name: "test",
          align: "left",
          text: "Test ID",
          field: "testId",
          sortable: true,
        },
        {
          name: "sampleReceivedDate",
          align: "left",
          text: "Receipts Date",
          field: "sampleReceiveDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "dateFirstTransmitted",
          align: "left",
          text: "Final Report Date",
          field: "dateFirstTransmitted",
        },
        {
          name: "sampleId",
          align: "left",
          text: "Sample",
          field: "sampleId",
          sortable: true,
        },
        {
          name: "dateSampleTaken",
          align: "left",
          text: "Sample Date Taken",
          field: "dateSampleTaken",
        },
        {
          name: "physicianLastName",
          align: "left",
          text: "Ordering Physician",
          field: "orderingPhysician",
          sortable: true,
        },
        {
          name: "patientLastName",
          align: "left",
          text: "Patient",
          field: "patient",
          sortable: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      orderBy: {
        internalId: "orderByInternalId",
        testId: "orderByTest",
        sampleReceiveDate: "orderBySampleReceivedDate",
        sampleId: "orderBySampleId",
        orderingPhysician: "orderByPhysicianLastName",
        patient: "orderBySamplePatientLastName",
      },
      searchFilter: {
        columns: 3,
        model: Order,
        fields: [
          {
            label: "Order ID",
            name: "internalId",
            type: "text",
          },
          {
            label: "Physician (NPI, Last Name, First name)",
            name: "physician",
            type: "text",
            fields: ["npiNumber", "name"],
            isMultiFields: true,
          },
          {
            label: "Patient",
            name: "patient",
            type: "text",
            fields: ["internalId", "firstName", "lastName", "email"],
            isMultiFields: true,
          },
          {
            label: "Test ID",
            name: "laboratoryTest",
            fields: ["label"],
            isMultiFields: true,
          },
          {
            label: "Receipts Date",
            name: "dateSampleReceived",
            type: "date",
          },
          {
            label: "Final Report Date",
            name: "dateFirstTransmitted",
            type: "date",
          },
        ],
      },
      limitData: [
        "internalId",
        this.buildSubQuery("test", [
          "label",
          this.buildSubQuery("cptCode", ["code", "description"]),
        ]),
        this.buildSubQuery("sample", ["sampleId", ["receiveDate"]]),
        "dateFirstTransmitted",
        "dateSampleTaken",
        this.buildSubQuery("physician", [
          "firstName",
          "lastName",
          this.buildSubQuery("stateMedicalLicense", ["licenseNumber"]),
          this.buildSubQuery("npi", ["npiNumber"]),
        ]),
        this.buildSubQuery("patient", [
          "firstName",
          "lastName",
          "internalId",
          "dateOfBirth",
        ]),
        "totalCount",
      ],
      clientLimitData: [
        "payorCode",
        "payorName",
        this.buildSubQuery("defaultAddress", [
          "line1",
          "line2",
          "city",
          "stateProvince",
          "postalCode",
        ]),
        this.buildSubQuery("defaultContactInformation", [
          this.buildSubQuery("mainPhone", ["phone"]),
          this.buildSubQuery("contactEmail", ["email"]),
        ]),
      ],
      additionalFilter: {
        payorAccount: {
          id: this.$route.params.id,
        },
      },
    };
  },
  computed: {
    getOrders() {
      let data = [];

      if (this.orders.length) {
        data = this.orders.map((item) => {
          const {
            id,
            internalId,
            test,
            sample,
            dateFirstTransmitted,
            dateSampleTaken,
            physician,
            patient,
          } = item;

          return {
            id,
            internalId: internalId || "--",
            testId: test ? test.label || "--" : "--",
            sampleReceiveDate:
              sample && sample.receiveDate
                ? this.$options.filters.formatDateTime(sample.receiveDate, {
                    format: "MM/DD/YYYY",
                  }) || "--"
                : "--",
            dateFirstTransmitted: dateFirstTransmitted
              ? this.$options.filters.parseDateFromString(
                  dateFirstTransmitted,
                  "MM/DD/YYYY"
                ) || "--"
              : "--",
            sampleId: sample ? sample.sampleId || "--" : "--",
            dateSampleTaken: dateSampleTaken || "--",
            orderingPhysician: physician
              ? this.sanitizeData(
                  {
                    firstName: physician.firstName,
                    lastName: physician.lastName,
                    npiNumber: physician.npi
                      ? physician.npi.npiNumber || "--"
                      : "--",
                  },
                  "<lastName>, <firstName> (<npiNumber>)"
                )
              : "--",
            patient: patient
              ? this.sanitizeData(
                  patient,
                  "<lastName>, <firstName> (<internalId>)"
                )
              : "--",
            patientName: patient
              ? this.sanitizeData(patient, "<lastName>, <firstName>")
              : "--",
            patientDOB: patient ? patient.dateOfBirth || "--" : "--",
            cptCode: test && test.cptCode ? test.cptCode.code || "--" : "--",
            cptDescription:
              test && test.cptCode ? test.cptCode.description || "--" : "--",
            amount: "",
            misCode: "",
            adjustment: "",
            balance: "",
          };
        });
      }

      return data;
    },
  },
  methods: {
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);

        const result = await this.actions.getPayorAccount({
          limitData: this.clientLimitData,
          variables: {
            id: this.$route.params.id,
          },
        });

        if (result) this.populateClient(result);
      } catch (e) {
        throw Error(e);
      }
    },
    async searchResults(result) {
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.orders = result;
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    sanitizeData(data, format) {
      let renderKeys = format.match(/(<([^>]+)>)/gi);
      renderKeys = renderKeys.map((key) => {
        return key.replace(/<|>/g, "");
      });

      return this.renderFormat(data, renderKeys, format);
    },
    renderFormat(data, renderKeys, format) {
      // get values from keys
      let renderObj;
      let renderString;

      for (var k = 0; k < renderKeys.length; k++) {
        const regex = new RegExp("<" + renderKeys[k] + ">");

        if (k === 0) {
          renderString = format.replace(regex, data[renderKeys[k]]);
        } else {
          renderString = renderString.replace(regex, data[renderKeys[k]]);
        }
      }

      renderObj = renderString;

      return renderObj;
    },
    showGenerateModal(data) {
      this.forGeneration = data;
      this.generateModalVisibility = true;
    },
    cancelGeneration() {
      this.generateModalVisibility = false;
    },
    populateClient(data) {
      const {
        payorCode,
        payorName,
        defaultAddress,
        defaultContactInformation,
      } = data;
      const { mainPhone = null, contactEmail = null } =
        defaultContactInformation || {};

      this.client = {
        payorCode,
        payorName,
        defaultAddress: defaultAddress
          ? {
              line1: defaultAddress.line1,
              line2: defaultAddress.line2,
              city: defaultAddress.city,
              stateProvince: defaultAddress.stateProvince,
              postalCode: defaultAddress.postalCode,
            }
          : {
              line1: "",
              line2: "",
              city: "",
              stateProvince: "",
              postalCode: "",
            },
        mainPhone: mainPhone ? mainPhone.phone : "",
        contactEmail: contactEmail ? contactEmail.email : "",
      };
    },
    resetDataTableOptions() {
      this.$refs.dataTable.resetDataTableOptions();
    },
  },
  async mounted() {
    try {
      if (this.$route.params.id) {
        await this.fetch();
        this.isLoading = false;
      }
    } catch (err) {
      this.showNotifyMessage({
        message: "Unable to fetch data.",
        type: "danger",
      });
    }
  },
};
